import { parseStampTime } from '../../utils/timeStamp'
import React, { useEffect, useState } from 'react'

import { IMGHOST } from '@/utils/ENV'
import Router from 'next/router'
import errorImg from '../../../public/assets/image/hubeiribao/error.png'
import commonStyles from '../../styles/common.module.css'
import Crypto from '../../utils/crypto'
import Image from 'next/image'

const TagItem = props => {
  // 剩余数组
  const [newArticles, setNewArticles] = useState([])
  // 4个为一组的数组
  const [fourArticles, setFourArticles] = useState([])
  // 界面宽度
  const pagewidth = props.width

  const getArticles = () => {
    let advertisementImg =
      props.imagesList.hengChangs.length !== 0 ? props.imagesList.hengChangs.slice(1) : []
    let result = []
    // 数组分组处理
    let arr = []
    let len = props.data.length
    let chunk = 4
    for (let i = 0, j = len; i < j; i += chunk) {
      arr.push(props.data.slice(i, i + chunk))
    }
    // 广告添加事宜
    /**
     * 4个为一组的数组，每隔一组使用一个索引为偶数广告
     * 剩余数组，取剩下的广告没有就不用
     */
    let leng = 0
    arr?.map((item, index) => {
      if (item.length === 4) {
        item?.map(Item => {
          let list = Item.article_set.slice(0, 5)
          Item.list = [...list]
        })
        result.push(item)
        if (index <= advertisementImg.length - 1 && advertisementImg.length !== 0) {
          leng += 1
          result.push({
            img: IMGHOST + advertisementImg[index].img,
            link: advertisementImg[index].link,
            title: advertisementImg[index].title,
          })
        }
      } else {
        let data = [...item]
        if (advertisementImg.length !== 0) {
          let index = data.findIndex(item => item.img)
          if (index === -1) {
            data?.map((Item, Index) => {
              if (
                Index === parseInt(Index / 2) * 2 &&
                leng + parseInt(Index / 2) + 1 <= advertisementImg.length
              ) {
                data.splice(parseInt(Index / 2) * 2 + 1, 0, {
                  img: IMGHOST + advertisementImg[leng + parseInt(Index / 2)].img,
                  link: advertisementImg[leng + parseInt(Index / 2)].link,
                  title: advertisementImg[leng + parseInt(Index / 2)].title,
                })
              }
            })
          }
        }
        data?.map(Item => {
          if (Item.article_set !== undefined) {
            Item.list = [...Item.article_set].slice(0, 7)
          }
        })
        setNewArticles(data)
      }
    })
    setFourArticles([...result])
  }

  useEffect(() => {
    getArticles()
  }, [props.imagesList])

  // 鼠标移入事件
  const omMouseEnterOrLeave = (id, type) => {
    let articles = [...newArticles]
    for (let i = 0; i < articles.length; i++) {
      if (articles[i].img === undefined) {
        for (let k = 0; k < articles[i].list.length; k++) {
          if (articles[i].list[k].id === id) {
            articles[i].list[k].isEnter = type
            setNewArticles(articles)
            break
          }
        }
      }
    }
  }
  const omMouseEnterOrLeave1 = (id, type, index) => {
    let article = [...fourArticles]
    for (let i = 0; i < article[index].length; i++) {
      for (let k = 0; k < article[index][i].list.length; k++) {
        if (article[index][i].list[k].id === id) {
          article[index][i].list[k].isEnter = type
          setFourArticles(article)
          break
        }
      }
    }
  }

  // 图片错误处理
  const changeImg = env => {
    let img = env.target
    img.src = errorImg.src
    img.onerror = null
  }

  return (
    <div>
      {fourArticles.length !== 0 && (
        <div>
          {fourArticles?.map((item, index) => {
            return (
              <div key={item.img ? item.img : index}>
                {item.img === undefined && (
                  <div>
                    <div className={commonStyles.tag_title_style}>
                      <span>{item[0].name}</span>
                      <span style={{ margin: '0px 0.05rem' }}>▪</span>
                      <span>{item[1].name}</span>
                      <span style={{ margin: '0px 0.05rem' }}>▪</span>
                      <span>{item[2].name}</span>
                      <span style={{ margin: '0px 0.05rem' }}>▪</span>
                      <span>{item[3].name}</span>
                    </div>
                    <div
                      className={commonStyles.module_four_box}
                      style={{ display: pagewidth > 800 ? 'flex' : 'block' }}
                    >
                      {item?.map((Item, Index) => {
                        return (
                          <div
                            key={Index}
                            style={{ width: pagewidth > 800 ? 'calc(50% - 5px)' : 'auto' }}
                          >
                            <div
                              style={{
                                lineHeight: '0.4rem',
                                fontSize: '0.26rem',
                                cursor: 'pointer',
                                WebkitTapHighlightColor: 'transparent',
                              }}
                              onClick={() => {
                                let data = {
                                  articleId: Item.id,
                                  newsId: props.newsId,
                                }
                                let data1 = Crypto.Encrypt(JSON.stringify(data))
                                Router.push({
                                  pathname: `/column_article`,
                                  query: {
                                    data: data1,
                                  },
                                })
                              }}
                            >
                              {Item.name}
                            </div>
                            {Item.list.length !== 0 && (
                              <div style={{ marginBottom: '0.2rem' }}>
                                {Item.list?.map(j => {
                                  return (
                                    <div key={j.id}>
                                      <div
                                        className={commonStyles.text_over}
                                        style={{
                                          color: j.isEnter ? '#7db800' : '#686868',
                                          fontSize: '0.18rem',
                                        }}
                                        onMouseEnter={() => {
                                          omMouseEnterOrLeave1(j.id, true, index)
                                        }}
                                        onMouseLeave={() => {
                                          omMouseEnterOrLeave1(j.id, false, index)
                                        }}
                                        onClick={() => {
                                          let data = {
                                            articleId: j.id,
                                            tagId: Item.id,
                                            newsId: props.newsId,
                                          }
                                          let data1 = Crypto.Encrypt(JSON.stringify(data))
                                          Router.push({
                                            pathname: '/article',
                                            query: {
                                              data: data1,
                                            },
                                          })
                                        }}
                                      >
                                        {j.name}
                                      </div>
                                      <div
                                        style={{
                                          fontSize: '0.11rem',
                                          color: '#cecece',
                                          marginTop: '0.06rem',
                                        }}
                                      >
                                        <span>发布于{parseStampTime(j.create_time)}</span>
                                      </div>
                                    </div>
                                  )
                                })}
                              </div>
                            )}
                          </div>
                        )
                      })}
                    </div>
                  </div>
                )}
                {item.img !== undefined && (
                  <Image
                    width={20}
                    height={20}
                    alt={item.title}
                    src={item.img}
                    onClick={() => {
                      window.open(`${item.link}`)
                    }}
                    style={{
                      maxWidth: '1100px',
                      width: '100%',
                      height: 'auto',
                      objectFit: 'cover',
                      display: 'block',
                      margin: '10px 0px',
                    }}
                  />
                )}
              </div>
            )
          })}
        </div>
      )}
      {newArticles.length !== 0 && (
        <div>
          {newArticles?.map((item, index) => {
            return (
              <div key={item.id ? item.id : item.img}>
                {item.img !== undefined && (
                  <Image
                    width={20}
                    height={20}
                    alt={item.title}
                    src={item.img}
                    style={{
                      maxWidth: '1100px',
                      width: '100%',
                      height: 'auto',
                      objectFit: 'cover',
                      display: 'block',
                      margin: '10px 0px',
                    }}
                    onClick={() => {
                      window.open(`${item.link}`)
                    }}
                  />
                )}
                {item.img === undefined && (
                  <div>
                    <div
                      className={commonStyles.tag_title_style}
                      style={{ cursor: 'pointer' }}
                      onClick={() => {
                        let data = {
                          articleId: item.id,
                          newsId: props.newsId,
                        }
                        let data1 = Crypto.Encrypt(JSON.stringify(data))
                        Router.push({
                          pathname: `/column_article`,
                          query: {
                            data: data1,
                          },
                        })
                      }}
                    >
                      {item.name}
                    </div>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'flex-start',
                      }}
                    >
                      <div className={commonStyles.down_sanjiao}></div>
                    </div>
                    {item.list.length !== 0 && (
                      <div style={{ display: 'flex', paddingTop: '0.1rem' }}>
                        <div>
                          <img
                            src={
                              item.list[0].imgList.length !== 0
                                ? item.list[0].imgList[0]
                                : errorImg.src
                            }
                            style={{
                              width: '1.6rem',
                              height: '1.1rem',
                              display: 'block',
                              objectFit: 'cover',
                              objectPosition: 'center 0',
                            }}
                            onError={env => {
                              changeImg(env)
                            }}
                          />
                          <div
                            className={commonStyles.text_over}
                            style={{
                              width: '1.6rem',
                              fontSize: '0.18rem',
                              paddingTop: '0.06rem',
                            }}
                            onClick={() => {
                              let data = {
                                articleId: item.list[0].id,
                                tagId: item.id,
                                newsId: props.newsId,
                              }
                              let data1 = Crypto.Encrypt(JSON.stringify(data))
                              Router.push({
                                pathname: '/article',
                                query: {
                                  data: data1,
                                },
                              })
                            }}
                          >
                            {item.list[0].name}
                          </div>
                        </div>
                        <div
                          style={{
                            paddingLeft: '0.2rem',
                            width: 'calc(100% - 1.6rem)',
                            display: pagewidth > 800 ? 'flex' : 'block',
                            justifyContent: 'space-between',
                            flexWrap: 'wrap',
                            alignItems: 'flex-start',
                          }}
                        >
                          {item.list.slice(1)?.map((j, Index) => {
                            return (
                              pagewidth > 800 && (
                                <div key={Index} style={{ width: 'calc(50% - 5px)' }}>
                                  <div
                                    className={commonStyles.text_over}
                                    style={{
                                      color: j.isEnter ? '#7db800' : '#686868',
                                      fontSize: '0.18rem',
                                    }}
                                    onMouseEnter={() => {
                                      omMouseEnterOrLeave(j.id, true)
                                    }}
                                    onMouseLeave={() => {
                                      omMouseEnterOrLeave(j.id, false)
                                    }}
                                    onClick={() => {
                                      let data = {
                                        articleId: j.id,
                                        tagId: item.id,
                                        newsId: props.newsId,
                                      }
                                      let data1 = Crypto.Encrypt(JSON.stringify(data))
                                      Router.push({
                                        pathname: '/article',
                                        query: {
                                          data: data1,
                                        },
                                      })
                                    }}
                                  >
                                    {j.name}
                                  </div>
                                  <div
                                    style={{
                                      fontSize: '0.11rem',
                                      color: '#cecece',
                                      marginTop: '0.06rem',
                                    }}
                                  >
                                    <span>发布于{parseStampTime(j.create_time)}</span>
                                  </div>
                                </div>
                              )
                            )
                          })}
                          {item.list.slice(1)?.map((j, Index) => {
                            return (
                              pagewidth < 800 &&
                              Index < 3 && (
                                <div key={Index}>
                                  <div
                                    className={commonStyles.text_over}
                                    style={{
                                      color: j.isEnter ? '#7db800' : '#686868',
                                      fontSize: '0.18rem',
                                    }}
                                    onMouseEnter={() => {
                                      omMouseEnterOrLeave(j.id, true)
                                    }}
                                    onMouseLeave={() => {
                                      omMouseEnterOrLeave(j.id, false)
                                    }}
                                    onClick={() => {
                                      let data = {
                                        articleId: j.id,
                                        tagId: item.id,
                                        newsId: props.newsId,
                                      }
                                      let data1 = Crypto.Encrypt(JSON.stringify(data))
                                      Router.push({
                                        pathname: '/article',
                                        query: {
                                          data: data1,
                                        },
                                      })
                                    }}
                                  >
                                    {j.name}
                                  </div>
                                  <div
                                    style={{
                                      fontSize: '0.11rem',
                                      color: '#cecece',
                                      marginTop: '0.06rem',
                                    }}
                                  >
                                    <span>发布于{parseStampTime(j.create_time)}</span>
                                  </div>
                                </div>
                              )
                            )
                          })}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )
          })}
        </div>
      )}
    </div>
  )
}

export default TagItem
