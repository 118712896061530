import { parseStampTime } from '../../utils/timeStamp'
import { useState } from 'react'
import TagItem12 from '../TagItem/TagItem12'
import { IMGHOST } from '@/utils/ENV'
import styles from '../../styles/Home/Home2.module.css'
import commonStyles from '../../styles/common.module.css'
import Router from 'next/router'
import { friendshipLink } from '../../utils/friendshipLink'
import HotMore from '../common/HotMore'
import AdvertisementBox from '../common/AdvertisementBox'
import WelfareAdvertisementBox from '../common/WelfareAdvertisementBox'
import errorImg from '../../../public/assets/image/hubeiribao/error.png'
import Crypto from '../../utils/crypto'
import Image from 'next/image'

export default function Home(props) {
  const newsId = props.newsId
  const hot = props.hot
  const pagewidth = props.width
  const [rendList] = useState(props.data)
  // 广告开启关闭状态
  const [advertisementStatus, setAdvertisementStatus] = useState({ value: true })
  // 广告
  const { hengDuans, footers, cebianImgs, welfareImgs } = props.imagesList

  // 图片错误处理
  const changeImg = env => {
    let img = env.target
    img.src = errorImg.src
    img.onerror = null
  }

  return (
    <div className={styles.contentBody}>
      <div style={{ maxWidth: '1100px', margin: pagewidth > 800 ? 'auto' : '0px 10px' }}>
        {hengDuans.length !== 0 && (
          <div className={commonStyles.content_top_img}>
            {hengDuans?.map((item, index) => {
              return (
                <Image
                  width={20}
                  height={20}
                  key={index}
                  src={IMGHOST + item.img}
                  alt={item.title}
                  onClick={() => {
                    window.open(`${item.link}`)
                  }}
                  onError={env => {
                    changeImg(env)
                  }}
                />
              )
            })}
          </div>
        )}
        <div
          style={{
            borderBottom: '1px solid #F04F13',
            color: '#F02413',
            fontSize: '0.22rem',
            lineHeight: '0.4rem',
          }}
        >
          热点
        </div>
        {hot.length !== 0 && (
          <div style={{ padding: '0.15rem 0px' }}>
            <div
              style={{
                display: pagewidth >= 1100 ? 'flex' : 'block',
                flexWrap: 'wrap',
                justifyContent: 'space-between',
              }}
            >
              {hot?.map((item, index) => {
                return (
                  <div key={index} style={{ width: pagewidth >= 1100 ? '48%' : '100%' }}>
                    <div
                      className={`${styles.template_hot_1} ${commonStyles.text_over}`}
                      onClick={() => {
                        let data = {
                          articleId: item.article.id,
                          newsId: newsId,
                        }
                        let data1 = Crypto.Encrypt(JSON.stringify(data))
                        Router.push({
                          pathname: '/article',
                          query: {
                            data: data1,
                          },
                        })
                      }}
                    >
                      {item.article.name}
                    </div>
                    <div className={styles.template_hot_title}>
                      <span>置顶</span>
                      <span>{parseStampTime(item.article.create_time)}</span>
                    </div>
                  </div>
                )
              })}
            </div>
            {hot.length > 9 && <HotMore newsId={newsId} />}
          </div>
        )}
        <div
          style={{
            borderBottom: '1px solid #F04F13',
            color: '#F02413',
            fontSize: '0.22rem',
            lineHeight: '0.4rem',
          }}
        >
          新闻
        </div>
        <TagItem12
          newsId={newsId}
          data={rendList}
          width={pagewidth}
          imagesList={props.imagesList}
        />
        {hot.length === 0 && rendList.length === 0 && (
          <div className={commonStyles.not_data}>暂无数据</div>
        )}
        <div
          className={styles.bottom_blank}
          style={{
            opacity: pagewidth > 648 ? 0 : 1,
          }}
        >
          {'没有更多了'}
        </div>
      </div>
      <WelfareAdvertisementBox width={pagewidth} data={welfareImgs} />
      {/* 友情链接 */}
      {friendshipLink.length !== 0 && (
        <div className={styles.friend_ship}>
          <div className={styles.friend_ship_box}>
            {friendshipLink?.map((item, index) => {
              return (
                <div
                  key={index}
                  className={styles.friend_ship_item}
                  onClick={() => {
                    window.open(item.link)
                  }}
                >
                  {item.name}
                </div>
              )
            })}
          </div>
        </div>
      )}
      {footers.length !== 0 && (
        <div className={styles.footer_images}>
          {footers.slice(0, 4)?.map((item, index) => {
            return (
              <Image
                width={20}
                height={20}
                key={index}
                src={IMGHOST + item.img}
                alt={item.title}
                onClick={() => {
                  window.open(`${item.link}`)
                }}
                onError={env => {
                  changeImg(env)
                }}
              />
            )
          })}
        </div>
      )}
      {/* 广告 */}
      {pagewidth > 1100 && cebianImgs.length !== 0 && advertisementStatus.value === true && (
        <AdvertisementBox
          data={cebianImgs}
          down={() => {
            setAdvertisementStatus({ value: false })
          }}
        />
      )}
    </div>
  )
}
